footer {
	height: 50px;
	line-height: 50px;
	font-size: 8px;
	text-transform: uppercase;
	font-family: @montserrat-regular;
	letter-spacing: 0.9px;
	position: fixed;
	bottom: 0;
	left: 0;
	width: 100%;
	background-color: #fff;
	z-index: 10;

	@media screen and (min-width: 1200px) {
		height: 100px;
		line-height: 100px;
	}

	&, a {
		color: @szurke;
	}

	#hirlevel-feliratkozas {
		a {
			font-size: 8px;
			letter-spacing: 0.9px;
			line-height: 1.2;
			font-family: @montserrat-regular;
			margin-bottom: 9px;
		}
	}

	@media screen and (max-width: 767px) {
		padding-left: 15px;
		padding-right: 15px;
	}

	@media screen and (min-width: 480px) {
		font-size: 13px;
		letter-spacing: 1.3px;

		#hirlevel-feliratkozas {
			a {
				margin-bottom: 9px;
				font-size: 13px;
				letter-spacing: 1.3px;
			}
		}
	}

	@media screen and (min-width: 1200px) {
		#hirlevel-feliratkozas {
			a {
				margin-bottom: 32px;
			}
		}
	}
}

