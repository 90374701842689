header {
	.navbar-brand {
		display: block;

//		background: url("@{images-url}logo.png") no-repeat 0 center transparent;
		width: 65%;
		margin: 0;
		padding: 0;
		max-width: 276px;
		max-height: 100px;

		span {
			font-size: 0;
			text-indent: -8000px;
		}

		svg {
			width: 100%;
			height: 100%;
		}
	}

	.navbar-default {
		.navbar-nav {
			& > li > a {
				color: @szurke;
				font-family: @montserrat-bold;
				font-size: 14px;
				text-transform: uppercase;
				text-align: right;

				@media (min-width: 992px) and (max-width: 1199px) {
					font-size: 13px;
					padding-left: 8px;
					padding-right: 8px;
				}
			}

			&.active > a,
			&.active > a:hover {
				background-color: transparent;
			}
		}
	}

	.navbar-default .navbar-nav > .active > a, .navbar-default .navbar-nav > .active > a:hover, .navbar-default .navbar-nav > .active > a:focus {
		background-color: transparent;
		color: @szurkeHover;
	}

	@media screen and (max-width: 767px) {
		.container-fluid {
			padding-left: 15px;
		}
	}
}



@media (min-width: 768px) {
	.navbar > .container-fluid .navbar-brand {
		margin-left: 0;
	}
}
