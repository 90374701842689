// TODO: app.less elején importáld

@root-url:				"../../";
@fonts-url:				"@{root-url}fonts/";
@images-url:			"@{root-url}images/";
@datauri-images-url:    "../../../public/images/"; // data-urihoz lesshez képesti relatív útvonal kell

svg {
	vertical-align: middle;
}

.nrp {
	padding-right: 0 !important;
}

.vertical-align {
	font-size: 0;

	&:before {
		content: '';
		display: inline-block;
		height: 100%;
		vertical-align: middle
	}

	.vertical-align-middle {
		vertical-align: middle;
		display: inline-block;
		max-width: 100%
	}
}

// Border Radius
.border-radius(@radius) {
	-webkit-border-radius: @radius;
	-moz-border-radius: @radius;
	border-radius: @radius;
}

// Single Corner Border Radius
.border-top-left-radius(@radius) {
	-webkit-border-top-left-radius: @radius;
	-moz-border-radius-topleft: @radius;
	border-top-left-radius: @radius;
}
.border-top-right-radius(@radius) {
	-webkit-border-top-right-radius: @radius;
	-moz-border-radius-topright: @radius;
	border-top-right-radius: @radius;
}
.border-bottom-right-radius(@radius) {
	-webkit-border-bottom-right-radius: @radius;
	-moz-border-radius-bottomright: @radius;
	border-bottom-right-radius: @radius;
}
.border-bottom-left-radius(@radius) {
	-webkit-border-bottom-left-radius: @radius;
	-moz-border-radius-bottomleft: @radius;
	border-bottom-left-radius: @radius;
}

// Single Side Border Radius
.border-top-radius(@radius) {
	.border-top-right-radius(@radius);
	.border-top-left-radius(@radius);
}
.border-right-radius(@radius) {
	.border-top-right-radius(@radius);
	.border-bottom-right-radius(@radius);
}
.border-bottom-radius(@radius) {
	.border-bottom-right-radius(@radius);
	.border-bottom-left-radius(@radius);
}
.border-left-radius(@radius) {
	.border-top-left-radius(@radius);
	.border-bottom-left-radius(@radius);
}

// Drop shadows
.box-shadow(@shadow) {
	-webkit-box-shadow: @shadow;
	-moz-box-shadow: @shadow;
	box-shadow: @shadow;
}

.img-responsive-horizontal {
	.img-responsive;
	max-height: 100%;
	max-width: none;
	width: auto;
}

.img-responsive-75-v {
	max-height: 75vh;
	@media (min-width: 767px) {
		max-width: 75vh !important;
	}
}

.uzenet {
	position: absolute;
	z-index: 10000;
	left: 0;
	width: 100%;
	top: 25px;

	li {
		list-style-type: none;
	}
}