@import "_defaults";
@import "fonts";
@import "bootstrap/bootstrap";

strong {
  font-weight: normal;
  font-family: @montserrat-bold;
}

article {
  padding: 45px 0 30px;
}

h2 {
  font-size: 25px;
  color: @szurkeHover;
  font-family: @montserrat-bold;
  text-transform: uppercase;
  letter-spacing: 5px;
  line-height: 30px;
  margin: 0 0 20px;
}

.tovabb.kicsi {

  display: inline-block;

  &, svg {
    width: 30px;
    height: 30px;
  }

  svg {
    fill: @szurke;
  }

  &:hover {
    svg {
      fill: #fff;
    }
  }
}

.tovabb-toggle,
.svgs {
  padding-left: 0;

  &, & > span {
    line-height: 20px !important;
    vertical-align: bottom;
  }

  svg {
    width: 20px;
    height: 20px;
    fill: @szurke;
  }

  &:hover {
    svg {
      fill: @szurkeHover;
    }
  }
}

#intro-holder {
  height: 100vh;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-color: #fff;
  z-index: 10000;
  padding: 50px 0;
  opacity: 1;
  .transition(all 0.5s linear);
  display: none;

  &.show-intro {
    display: block;
  }

  &.intro-hidden {
    opacity: 0;
  }

  @media screen and (min-width: 768px) {
    padding: 50px;
  }

  @media screen and (min-width: 1200px) {
    padding: 100px;
  }

  //	display: none;

  #intro {
    width: 100%;
    height: 100%;
    background: url("@{images-url}intro.jpg") no-repeat center center transparent;
    background-size: cover;
    display: table;

    #intro-logo {
      display: table-cell;
      width: 100%;
      height: 100%;
      vertical-align: middle;
      text-align: center;

      svg {
        margin: 0 auto;
        fill: #fff;
        max-width: 50%;
        max-height: 30%;
      }
    }
  }
}

.carousel-holder {
  height: 100vh;

  #carouselHacked {
    height: 100%;
    padding-top: 100px;
    padding-bottom: 50px;

    @media screen and (min-width: 1200px) {
      padding-bottom: 100px;
    }

    .carousel-inner {
      height: 100%;

      .item {
        height: 100%;
        background-repeat: no-repeat;
        //			background-position: right center;
        background-size: cover;
      }
    }
  }
}

/*
inspired from http://codepen.io/Rowno/pen/Afykb
*/
.carousel-fade .carousel-inner .item {
  opacity: 0;
  transition-property: opacity;
}

.carousel-fade .carousel-inner .active {
  opacity: 1;
}

.carousel-fade .carousel-inner .active.left,
.carousel-fade .carousel-inner .active.right {
  left: 0;
  opacity: 0;
  z-index: 1;
}

.carousel-fade .carousel-inner .next.left,
.carousel-fade .carousel-inner .prev.right {
  opacity: 1;
}

.carousel-fade .carousel-control {
  display: none;
}

/*
WHAT IS NEW IN 3.3: "Added transforms to improve carousel performance in modern browsers."
now override the 3.3 new styles for modern browsers & apply opacity
*/
@media all and (transform-3d), (-webkit-transform-3d) {
  .carousel-fade .carousel-inner > .item.next,
  .carousel-fade .carousel-inner > .item.active.right {
    opacity: 0;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }

  .carousel-fade .carousel-inner > .item.prev,
  .carousel-fade .carousel-inner > .item.active.left {
    opacity: 0;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }

  .carousel-fade .carousel-inner > .item.next.left,
  .carousel-fade .carousel-inner > .item.prev.right,
  .carousel-fade .carousel-inner > .item.active {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

/* just for demo purpose */
html,
body,
.carousel,
.carousel-inner,
.carousel-inner .item {
  //	height: 100%;
}

.carousel-caption {
  left: 30px;
  right: auto;
  text-align: left;
  padding-bottom: 5px;
  max-width: 510px;

  h2 {
    color: #fff;
    font-size: 40px;
    text-transform: uppercase;
    font-family: @montserrat-bold;
    letter-spacing: 8px;
    line-height: 45px;
  }

  .leiras {
    color: @szurke;
    font-size: 25px;
    font-family: @montserrat-light;
    margin: 0 0 15px;

    p {
      margin: 0;
    }
  }

  @media screen and (max-width: 767px) {

    h2 {
      font-size: 30px;
      letter-spacing: 5px;
    }

    .leiras {
      font-size: 18px;
    }
  }

  @media screen and (min-width: 768px) {
    left: 60px;
    padding-bottom: 35px;
  }

  @media screen and (min-width: 992px) {
    left: 90px;
    padding-bottom: 46px;
  }
}

.biglead {
  font-family: @montserrat-light;
  font-size: 30px;
  color: @szurkeHover;
  line-height: 36px;
  padding-bottom: 80px;
}

.szolgaltatasok,
.hirek {
  padding: 30px 45px 0;

  .row {
    border-bottom: 2px solid #fff;
    padding: 20px 0;

    &:last-child {
      border-bottom: none;
    }
  }

  .img-holder {
    img {
      .border-radius(90px);
      margin-left: auto;
      margin-right: auto;
    }
  }

  .lead {
    font-family: @montserrat-light;
    font-size: 20px;
    line-height: 1.2;
    color: @szurke;
  }

  .biglead,
  h2,
  .lead,
  .leiras {
    max-width: 830px;
  }

  .tovabb-toggle,
  .vissza-toggle,
  .kapcsolat,
  .tovabb {
    margin-top: 10px;
  }

  @media screen and (max-width: 767px) {
    .img-holder {
      padding-bottom: 20px;
    }

    h2 {
      font-size: 20px;
      letter-spacing: 3px;
    }

    .lead {
      font-size: 18px;
    }
  }

}

.szolgaltatasok {
  .leiras {
    font-family: @montserrat-light;
    font-size: 14px;
    line-height: 21px;
    color: #444444;
    padding-bottom: 20px;
  }

  .leiras,
  .vissza-toggle,
  .kapcsolat {
    display: none;
  }
}

.szolgaltatas-galeria {
  margin-top: 20px;

  a {
    display: block;
    height: 100px;
    float: left;
    margin: 0 5px 5px 0;

    &:last-child {
      margin-right: 0;
    }
  }
}

.portfolio {
  padding: 15px;
}

.masonry-cols {
  .set-grid-size,
  .masonry-elem {
    float: left;
    width: 100%;
    height: auto;
    overflow: hidden;

    @media (min-width: 768px) and (max-width: 991px) {
      width: 33.33333333%;
    }

    @media (min-width: 992px) {
      width: 25%;
    }
  }
}

.portfolio-holder {

  .album-container {
    margin: 5px;
  }

  .kep-container {
    margin: 10px;
  }
  .album-container {
    .pluszjel {
      max-width: 18px;
      display: none;
    }
  }

  .album-container,
  .kep-container {

    .album,
    .kep {
      display: block;
      text-decoration: none;
      .transition(all 0.15s linear);

      &:hover {
        h3 {
          color: @szurkeHover;
          .pluszjel {
            max-width: 20px;
            margin-right: 10px;
            display: inline;
          }
        }

        .img-holder {
          position: relative;

          &:after {
            content: ' ';
            position: absolute;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
            margin: auto;
            width: 60px;
            height: 60px;
            display: block;
            background: url("@{images-url}svg/plusz_feher.svg") no-repeat center center transparent;
          }
        }

      }

      &.matt {
        .opacity(0.5);

        &:hover {
          .opacity(1);
        }
      }
    }

    img {
      margin: 0 0 11px;
    }

    h3 {
      text-transform: uppercase;
      color: @szurke;
      font-size: 14px;
      line-height: 16px;
      letter-spacing: 2.8px;
      font-family: @montserrat-bold;
      margin: 0;
      min-height: 35px;
    }
  }
}

.albumok {

  ul {
    margin-top: 10px;
  }

  li {
    margin-bottom: 10px;

    &.active {
      a {
        color: @szurkeHover;
      }
    }
  }

  a {
    text-transform: uppercase;
    color: @szurke;
    font-size: 14px;
    line-height: 16px;
    letter-spacing: 2.8px;
    font-family: @montserrat-bold;
    margin: 0;
    min-height: 35px;
    position: relative;
    padding: 0 0 0 30px;

    &:hover {
      color: @szurkeHover;
      text-decoration: none;
    }
  }

  .active a,
  a:hover {
    &:after {
      content: ' ';
      position: absolute;
      left: 0;
      top: 0;
      bottom: 0;
      margin-top: auto;
      margin-bottom: auto;
      width: 20px;
      height: 20px;
      display: block;
      background: url("@{images-url}svg/plusz.svg") no-repeat center center transparent;
    }
  }
}

.hirek {
  .datum {
    display: block;
    font-size: 14px;
    line-height: 16px;
    font-family: @montserrat-light;
    margin-bottom: 10px;
  }
}

.container-fluid {
  padding-left: 0;
  padding-right: 0;
}

.content {
  padding-top: 100px;
  padding-bottom: 50px;
  background-color: #EEEEEE;

  @media screen and (min-width: 768px) {
    padding-bottom: 100px;

    &.fooldal {
      padding: 0;
    }
  }

  &.fooldal {
    background-color: #fff;
    padding: 0;
  }
}

.content,
.header-content {
  margin-left: auto;
  margin-right: auto;
  max-width: 1700px;
}

.vissza,
.btn-default {
  color: @szurke;
  background-color: transparent;
  border: none;
  font-size: 12px;
  line-height: 1.2;
  letter-spacing: 2px;
  font-family: @montserrat-bold;
  text-transform: uppercase;
  text-decoration: none;
  .box-shadow(none);

  &:hover,
  &:focus,
  &.focus,
  &:active,
  &.active {
    background-color: transparent;
    color: @szurkeHover;
    .box-shadow(none);
  }

  @media screen and (min-width: 480px) {
    font-size: 14px;
    letter-spacing: 3.4px;
  }
}

.btn-default:hover,
.btn-default:focus,
.btn-default.focus,
.btn-default:active,
.btn-default.active {

}

.fancybox-overlay {
  overflow: hidden !important;
  background: #eeeeee none;
  //	position: relative;
}

.fancybox-lock {
  overflow: auto;
}

.fancybox-opened .fancybox-skin {
  .border-radius(0);
  .box-shadow(none);
  position: relative;

  .fancybox-inner {
    //		background: url(../img/bg.png) repeat 0 0 transparent;
  }
}

#hirlevel_feliratkozas {
  padding: 20px;
}

.fancybox-outer, .fancybox-inner {
  position: relative;
}

.fancybox-nav {
  position: absolute;
  top: 0;
  width: 40%;
  height: 100%;
  cursor: pointer;
  text-decoration: none;
  background: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  z-index: 8040;

  span {
    visibility: visible;
    width: 34px;
    height: 61px;
    margin-top: -30px;
    background: none;

    svg {
      fill: @szurke;
    }
  }
  &.fancybox-prev {
    left: 0;

    span {
      left: -65px;
    }
    &:hover {
      span {
        svg {
          fill: @szurkeHover;
        }
      }
    }
  }
  &.fancybox-next {
    span {
      right: -65px;
    }
    &:hover {
      span {
        svg {
          fill: @szurkeHover;
        }
      }
    }
  }
}

.fancybox-close {
  background: none;
  width: 45px;
  height: 45px;
  top: 0;
  right: -77px;

  svg {
    fill: @szurke;
  }

  &:hover {
    svg {
      fill: @szurkeHover;
    }
  }

  @media (max-width: 991px) {
    right: 0;
    top: -77px;
  }
}

.fancybox-opened {
  .fancybox-title {
    text-align: right;
    color: #fff;
    font-size: 16px;
  }
}

.fancy-oldalsav {
  height: 100%;
}

.fancy-outer-wrap {
  height: 100%;
  position: relative;
}

.albumnezegeto-container {
  display: none;
  .opacity(0);
  .transition(all 0.15s linear);
}

.albumnezegeto-container.visible + .portfolio {
  width: 100%;
  height: 100%;
  max-height: 100vh;
  overflow: hidden;
  top: 0;
  left: 0;
  position: fixed;
}

.albumnezegeto-container.visible {
  display: block;
  .opacity(1);
  position: fixed;
  background-color: #EEEEEE;
  top: 0;
  left: 0;
  z-index: 1031;
  width: 100%;
  height: 100%;
  max-height: 100vh;
  overflow: hidden;

  .albumnezegeto {
    width: 100%;
    position: relative;
  }

  .carousel-inner,
  .carousel-inner > .item {
    height: 100%;
    max-height: 100vh;
  }

  .item {
    .image-holder {
      max-width: 75%;
      max-height: 75%;
      margin: 0 auto;

      img {
        margin: 0 auto;
      }
    }
  }

  .carousel-control {
    background: none;
    width: 34px;
    height: 61px;
    margin-top: auto;
    margin-bottom: auto;

    svg {
      fill: @szurke;
    }

    &:hover {
      svg {
        fill: @szurkeHover;
      }
    }

    &.left {
      left: 3%;
    }

    &.right {
      right: 3%;
    }
  }

  .carousel-close,
  .carousel-info {
    position: absolute;
    display: block;
    top: 45px;
    z-index: 1;

    svg {
      fill: @szurke;
    }

    &:hover {
      svg {
        fill: @szurkeHover;
      }
    }
  }

  .carousel-close {
    width: 45px;
    height: 45px;
    right: 3%;
  }

  .albumnezegeto-wrap {
    position: relative;
    padding-top: 100px;
    padding-bottom: 100px;

    &.col-xs-12 {
      @media (max-width: 767px) {
        padding-left: 0;
        padding-right: 0;
      }
    }
  }

  .albumnezegeto-wrap,
  .carousel-info-blokk {
    .transition(all 0.15s linear);
  }

  .carousel-info {
    left: 3%;

    span {
      height: 45px;
      line-height: 45px;
      display: block;
      color: @szurke;
      font-family: @montserrat-bold;
      font-size: 14px;
      text-transform: uppercase;
      letter-spacing: 2.8px;
    }

    &:hover,
    &:active {
      text-decoration: none;

      span {
        color: @szurkeHover;
      }
    }

    svg {
      width: 45px;
      height: 45px;
      margin: 0 5px 0 0;
    }

    &.open {
      svg {
        transform: rotate(45deg);
        transform-origin: 50% 50%;
      }
    }

  }

  .carousel-info-blokk {
    padding-top: 55px;
    padding-bottom: 55px;
    .transition(all 0.15s linear);
    display: none;
    width: 0;

    &.col-xs-12 {
      @media (max-width: 767px) {
        //padding-left: 0;
        //padding-right: 0;
      }
    }

    &.open {
      display: block;
      width: 100%;
      background-color: #fff;

      @media screen and (min-width: 768px) {
        width: 25%;
        height: 100vh;
      }
    }

    h2 {
      color: @szurke;
      font-size: 20px;
      margin: 0 0 40px;
      line-height: 24px;
      letter-spacing: 4px;
    }

    .szoveg {
      font-family: @montserrat-light;
      font-size: 18px;
      line-height: 27px;
      margin: 0 0 30px;
    }

    .datum {
      font-family: @montserrat-light;
      font-size: 14px;
      letter-spacing: 2.8px;
      line-height: 16px;

      span {
        font-family: @montserrat-bold;
        text-transform: uppercase;
        margin: 0 5px 0 0;
      }
    }
  }
}

//@media screen and (min-width: 768px) {
.albumnezegeto-container.visible {
  .albumnezegeto-wrap,
  #albumnezegeto,
  .carousel-inner {
    height: 100vh;
  }

  .carousel-control {
    margin-top: 18%;
    margin-bottom: auto;
  }
}

.item {
  .image-holder {
    height: 75vh;
    width: 75vw;
  }
}

//}

.kapcsolat-group {
  font-family: @montserrat-light;
  font-size: 14px;
  line-height: 1.5;

  .name {

    font-size: 20px;
    color: #444;
    line-height: 1.2;
  }

  label {
    line-height: 1.2;
    font-weight: normal;
    display: block;
  }

  p {
    margin: 0;

    &.foglalkozas {
      margin-bottom: 25px;
    }
  }
}

form {
  .btn {

    span {
      height: 20px;
      line-height: 20px;
    }

    svg {
      width: 20px;
      height: 20px;
      fill: @szurke;
      margin: 0 10px 0 0;
    }

    &:hover {
      svg {
        fill: @szurkeHover;
      }
    }
  }
}

#map {
  width: 100%;
  height: 280px;
  margin: 30px 0;
}

textarea {
  width: 100%;
  height: 244px;
  border: none;
}

.hirlevel-modal {
  border: 50px solid #fff;
  background: #eeeeee;
  padding: 20px;

  h2 {
    color: @szurke;
    font-size: 20px;
  }
}

.modal-content {
  box-shadow: none;
  .border-radius(0);
}

@media screen and (min-width: 768px) and (max-width: 1199px) {

  .container-fluid {
    padding-left: 50px;
    padding-right: 50px;
  }

}

.szoveges-aloldal {
  img {
    .img-responsive;
  }
}

@media screen and (min-width: 1200px) {

  .container-fluid {
    padding-left: 100px;
    padding-right: 100px;
  }
}

@import "header";
@import "footer";

.grecaptcha-badge {
	z-index: 100;
}