@root-url:				"../../";
@fonts-url:				"@{root-url}fonts/";

@font-face {
	font-family: 'montserratregular';
	src: url('@{fonts-url}montserrat/montserrat-regular-webfont.eot');
	src: url('@{fonts-url}montserrat/montserrat-regular-webfont.eot?#iefix') format('embedded-opentype'),
	url('@{fonts-url}montserrat/montserrat-regular-webfont.woff') format('woff'),
	url('@{fonts-url}montserrat/montserrat-regular-webfont.ttf') format('truetype'),
	url('@{fonts-url}montserrat/montserrat-regular-webfont.svg#montserratregular') format('svg');
	font-weight: normal;
	font-style: normal;

}

@font-face {
	font-family: 'montserratbold';
	src: url('@{fonts-url}montserrat/montserrat-bold-webfont.eot');
	src: url('@{fonts-url}montserrat/montserrat-bold-webfont.eot?#iefix') format('embedded-opentype'),
	url('@{fonts-url}montserrat/montserrat-bold-webfont.woff') format('woff'),
	url('@{fonts-url}montserrat/montserrat-bold-webfont.ttf') format('truetype'),
	url('@{fonts-url}montserrat/montserrat-bold-webfont.svg#montserratbold') format('svg');
	font-weight: normal;
	font-style: normal;

}

@font-face {
	font-family: 'montserratlight';
	src: url('@{fonts-url}montserrat/montserrat-light-webfont.eot');
	src: url('@{fonts-url}montserrat/montserrat-light-webfont.eot?#iefix') format('embedded-opentype'),
	url('@{fonts-url}montserrat/montserrat-light-webfont.woff') format('woff'),
	url('@{fonts-url}montserrat/montserrat-light-webfont.ttf') format('truetype'),
	url('@{fonts-url}montserrat/montserrat-light-webfont.svg#montserratlight') format('svg');
	font-weight: normal;
	font-style: normal;

}